var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GmapMap',{ref:"mapRef",staticClass:"location-map m-t-50",style:('height: ' + _vm.mapsHeight),attrs:{"center":{ lat: 10, lng: 10 },"zoom":15,"map-type-id":"roadmap","options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }